/*

 Custom colors for Herbarium organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #166F3C;
$organization_primary_focus: #6BC069;
$organization_primary_disabled: #166F3C;

$organization_program_default: #166F3C;

$organization_secondary: #B5EEC4;
$organization_secondary_focus: #B5EEC4;
$organization_secondary_transparent: #6BC069;
$organization_secundary_border: #ffffff;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #166F3C;

$organization_secundary_button: #166F3C;
$organization_secundary_button_focus: #B5EEC4;
